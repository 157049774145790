import {isMobile} from '../../../commons/selectors/environment'
import {DetailedEvent, MemberPageContext} from '../../types/state'
import {connect} from '../connect'
import {EventDetails as EventDetailsPresentation} from './event-details'

export interface EventDetailsProps extends EventDetailsOwnProps, EventDetailsRuntimeProps {}

interface EventDetailsOwnProps {
  event: DetailedEvent
  primaryButton: React.ReactNode
  secondaryButton: React.ReactNode
  shareButton: React.ReactNode
}

interface EventDetailsRuntimeProps {
  mobile: boolean
}

const mapRuntime = ({state}: MemberPageContext): EventDetailsRuntimeProps => ({
  mobile: isMobile(state),
})

export const EventDetails =
  connect<EventDetailsOwnProps, EventDetailsRuntimeProps>(mapRuntime)(EventDetailsPresentation)
